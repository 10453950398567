<template>
    <layout class="wrapper article-detail">
        <!-- 面包屑 -->
        <div class="container mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/special-ma/index/'+$route.params.id}">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/special-ma/article-list/'+$route.params.id}">相关论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>论文详情</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="container flex mt20">
            <div class="guide-list-left">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 文章详情 -->
                    <div class="guidelist-article t-l pb5">
                        <p class="fs22 strong color333 mb10">{{ infomation.title }}</p>
                        <p class="fs12 color999 row-1 mb10" v-if="infomation.cn_title"><span class="strong">来源：</span>{{infomation.cn_title}}</p>
                        <p class="fs12 color999 row-1">
                            <span class="mr20"><span class="strong">阅读：</span>{{ infomation.hits }}次</span>
                            <span class="mr20" v-if='infomation.real_name'><span class="strong">作者：</span>{{ infomation.real_name }}</span>
                            <span class="mr20" v-if='infomation.org_cnname'><span class="strong">作者单位：</span>{{ infomation.org_cnname }}</span>
                        </p>
                    </div>
                    <div class="mt20">
                        <div class="t-l mb10 pore" v-if="infomation.img_path">
                            本论文被评为
                            <span class="strong">"电子壁报"</span>
                            <span v-if="(bibaoList[0]||{}).image_url" class="paper-btn ml10 color333 strong" @click="handlePreviewEvent('bibaoList',0)">
                                <img class="paper-img ml10 color333 strong" @load="handleLoadEvent($event,0)" :src="bibaoList[0].image_url | urlFilter(750)" alt="" />
                                点击观看</span>
                        </div>
                        <div class="t-l mb10" v-if="infomation.video_id && infomation.video_id != 0">
                            本论文被评为
                            <span class="strong">"大会发言"</span>
                            <span class="paper-btn ml10 color333 strong" @click="videoJumpDetail(infomation.video_id,infomation.listId,'ma',$route.params.id)">点击观看</span>
                        </div>
                        <div class="t-l" v-if="abstract.length" v-for="(item,index) in abstract" :key="index" v-html="item"></div>
                        <a-empty style="margin-top:100px;" v-if="!abstract.length && !loading" />
                    </div>
                </a-spin>
            </div>
            <div class="guide-list-right">
                <!-- 相关文章、相关类别、相关壁报、相关视频 -->
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <photoswipe ref="photoswipe" :picturelist="picList"></photoswipe>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import photoswipe from '@/components/photoswipe.vue';
import { videoJumpDetail } from '@/utils/jumpPageMethods';
import { committee_id } from '@/config';
export default {
    name: 'SpecialMaArticleDetail',
    components: {
        layout, layoutRight, photoswipe
    },
    data() {
        return {
            // id=2322310&meeting_id=17141
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        page: 1,
                        pageSize: 6,
                        topic_id: this.$route.params.id, 
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
            },
            title: '',
            picList: [],
            bibaoList: [],
            id: this.$route.query.id,
            infomation: {},
            abstract: [],
            loading: true,
        }
    },
    created() {
        this.pageInit()
    },
    methods: {
        videoJumpDetail,
        getInfoDetail() {
            return this.request.post('GetAbstractDetail', { id: this.id })
        },
        pageInit() {
            this.loading = true
            let requestArr = [
                this.getInfoDetail(),
            ]
            Promise.all(requestArr).then(res => {
                // 论文详情
                if(res[0].data[0]){
                    let infomation = res[0].data[0] || [];
                    // infomation
                    infomation.img_path = infomation.img_path.replace('https://files.sciconf.cn/', '');
                    this.infomation = infomation
                    // 壁报观看
                    this.bibaoList = [{
                        image_url: infomation.img_path,
                        title: this.infomation.title
                    }];
                    let abstract = this.infomation.abstract
                    let arr = []
                    abstract.forEach(item => {
                        for (let key in item) {
                            if (key != 'abstract_id') {
                                arr.push(item[key])
                            }
                        }
                    })
                    this.abstract = arr
                }else{
                    this.infomation = {}
                    this.abstract = []
                }
                

                this.loading = false;
            })
        },
        articleDetail(id) {
            this.id = id
            this.meeting_id = this.meeting_id
            this.pageInit()
        },
        // 读取图片高度
        handleLoadEvent(e, index) {
            this.$set(this.bibaoList[index], 'height', e.target.naturalHeight)
        },
        // 查看图片
        handlePreviewEvent(field, index) {
            this.picList = this[field];
            this.$nextTick(() => {
                this.$refs.photoswipe.showPhowoswipe(index, this.picList);
            })
        },
    }
}
</script>
<style lang="less" scoped>
.guide-list-left {
    width: 770px;
    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #d8d8d8;
    }

    .paper-btn {
        color: #1e51c9;
        cursor: pointer;
        position: relative;
        display: inline-block;
        .paper-img {
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
        }
    }
}

.guide-list-right {
    width: 398px;
    margin-top: -5px;
}

@media screen and (max-width: 768px) {
    .article-detail {
        display: block;
        margin-top: 10px;
        .container {
            display: block;
            padding: 20px 10px 0;
        }
        .mobile-bread {
            display: none;
        }
        
        .meeting-report {
            width: 100%;
        }
        .guide-list-left{
            width: 100%;
        }
        .guide-list-right,
        .meeting-right {
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>
